.ot-separator-white {
    width: 100%;
    max-width: 100%;
    height: 20px;
    background-color: #fbfbfd;;
}

.ot-separator-red {
    width: 100%;
    max-width: 100%;
    height: 30px;
    background-color: #ec3323;
/*     border-bottom: 8px solid #ec3323; */
   /*  border-bottom: 8px solid #fbfbfd; */
}
